export default {
  state: {
    goldToSilver: 0,
    silverToGold: 0,
    appVersion: "",
    bids: [],
  },
  mutations: {
    setConvert(state, convert) {
      state.goldToSilver = convert.goldToSilver;
      state.silverToGold = convert.silverToGold;
    },
    setAppVersion(state, appVersion) {
      state.appVersion = appVersion;
    },
    setBids(state, bids) {
      state.bids = bids;
    },
  },
  actions: {
    getConvert({ commit }) {
      // eslint-disable-next-line no-undef
      axios.get("/api/Metadata/GetExchangeRate").then((response) => {
        commit("setConvert", response.data.value);
      });
    },
    sendConvert({ state }) {
      // eslint-disable-next-line no-undef
      return axios
        .post("/api/Metadata/UpdateExchangeRate", {
          silverToGold: +state.silverToGold,
          goldToSilver: +state.goldToSilver,
        })
        .then(() => {
          return true;
        })
        .catch(() => {
          return false;
        });
    },
    getAppVersion({ commit }) {
      // eslint-disable-next-line no-undef
      axios.get("/api/Metadata/GetAppVersion").then((response) => {
        commit("setAppVersion", response.data.value.appVersion);
      });
    },
    updateAppVersion({ state }) {
      // eslint-disable-next-line no-undef
      return axios
        .post("/api/Metadata/UpdateAppVersion", {
          appVersion: state.appVersion,
        })
        .then(() => {
          return true;
        })
        .catch(() => {
          return false;
        });
    },
    getBids({ commit }) {
      // eslint-disable-next-line no-undef
      axios.get("/api/Metadata/GetBets").then((response) => {
        commit("setBids", response.data.value);
      });
    },
    createBid() {
      // eslint-disable-next-line no-undef
      return axios
        .post("/api/Metadata/AddBet", {
          betAmount: 1,
          currencyType: 1,
          typeGame: 0,
        })
        .then((response) => {
          return response.data.isSuccess;
        })
        .catch(() => {
          return false;
        });
    },
    updateBid(state, data) {
      // eslint-disable-next-line no-undef
      return axios
        .post("/api/Metadata/UpdateBet", data)
        .then((response) => {
          return response.data.isSuccess;
        })
        .catch(() => {
          return false;
        });
    },
    deleteBid(state, data) {
      // eslint-disable-next-line no-undef
      return axios
        .post("/api/Metadata/RemoveBet", data)
        .then((response) => {
          return response.data.isSuccess;
        })
        .catch(() => {
          return false;
        });
    },
  },
};
