<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <notifications />

    <side-bar>
      <sidebar-link to="/admins/">
        <p>Администраторы и роли</p>
      </sidebar-link>
      <sidebar-link to="/users/">
        <p>Пользователи</p>
      </sidebar-link>
      <sidebar-link to="/support/">
        <p>Обращения</p>
      </sidebar-link>
      <sidebar-link to="/payment-requests/">
        <p>Заявки на выплату</p>
      </sidebar-link>
      <sidebar-link to="/fast-messages/">
        <p>Быстрые фразы/эмоции</p>
      </sidebar-link>
      <sidebar-link to="/forbidden-words/">
        <p>Запрещенные слова</p>
      </sidebar-link>
      <sidebar-link to="/market/">
        <p>Магазин</p>
      </sidebar-link>
      <sidebar-link to="/calendar/">
        <p>Награда за вход</p>
      </sidebar-link>
      <sidebar-link to="/statistic/">
        <p>Статистика</p>
      </sidebar-link>
      <sidebar-link to="/settings/">
        <p>Настройки</p>
      </sidebar-link>
      <div class="sidebar-btn">
        <md-button class="md-primary" @click="logout"> Выйти </md-button>
      </div>
    </side-bar>
    <div class="main-panel">
      <DashboardContent />
    </div>
  </div>
</template>

<script>
import DashboardContent from "./Content.vue";

export default {
  components: {
    DashboardContent,
  },

  methods: {
    logout() {
      localStorage.removeItem("token");
      this.$router.push("/login");
    },
  },
};
</script>

<style lang="scss">
.sidebar-btn {
  margin: 16px;

  button {
    width: 100%;
  }
}
.m-auto {
  background: rgba(white, 0.6);
  padding: 5px 20px;
}
</style>
