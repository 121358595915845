export default {
  state: {
    showModal: false,
    modalComponent: null,
    modalData: null,
  },
  mutations: {
    openModal(state, data) {
      state.modalComponent = () =>
        import(`@/components/Modals/${data.modalName}.vue`);
      state.modalData = data.modalData || null;

      const body = document.querySelector("body");
      body.onkeyup = (e) => {
        if (e.which == 27) {
          this.commit("closeModal");
        }
      };
      body.classList.add("scroll--off");

      state.showModal = true;
    },
    closeModal(state) {
      state.showModal = false;

      const body = document.querySelector("body");
      body.classList.remove("scroll--off");
      body.onkeyup = () => {};

      state.modalComponent = null;
      state.modalData = null;
    },
  },
};
