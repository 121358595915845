import moment from "moment";

export default {
  state: {
    payments: [],
    pagination: null,
    currencyTypes: ["", "TON", "1PTON"],
  },
  mutations: {
    setPayments(state, payments) {
      state.payments = payments.paymentRequests.map((payment) => {
        return {
          ...payment,
          prepareDate: moment(payment.creationDate).format("DD.MM.YYYY"),
        };
      });
      state.pagination = payments.pagination;
    },
  },
  actions: {
    getPayments({ commit }, query) {
      const queryUrl = new URLSearchParams(query);
      // eslint-disable-next-line no-undef
      axios
        .get(`/PaymentRequest/GetAll?${queryUrl.toString()}`)
        .then((response) => {
          commit("setPayments", response.data.value);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    setStatus(state, data) {
      // eslint-disable-next-line no-undef
      return axios
        .post("/PaymentRequest/UpdateStatusPaymentRequest", data)
        .then((response) => {
          return {
            success: response.data.isSuccess,
            message: response.data.errors.length
              ? response.data.errors[0]
              : "Статус заявки успешно изменено",
          };
        })
        .catch((error) => {
          console.log(error);
          return {
            success: false,
            message: "Статус заявки не удалось изменить",
          };
        });
    },
    setComment(state, data) {
      // eslint-disable-next-line no-undef
      return axios
        .post("/PaymentRequest/UpdateCommentPaymentRequest", data)
        .then((response) => {
          return {
            success: response.data.isSuccess,
            message: response.data.errors.length
              ? response.data.errors[0]
              : "Комментарий заявки успешно изменен",
          };
        })
        .catch((error) => {
          console.log(error);
          return {
            success: false,
            message: "Комментарий заявки не удалось изменить",
          };
        });
    },
    setScreen(state, data) {
      // eslint-disable-next-line no-undef
      return axios
        .post("/PaymentRequest/UpdateScreenShotPaymentRequest", data)
        .then((response) => {
          return {
            success: response.data.isSuccess,
            message: response.data.errors.length
              ? response.data.errors[0]
              : "Скриншот заявки успешно изменен",
          };
        })
        .catch((error) => {
          console.log(error);
          return {
            success: false,
            message: "Скриншот заявки не удалось изменить",
          };
        });
    },
  },
};
