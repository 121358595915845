export default {
  state: {
    timeToken: null,
    token: null,
  },
  mutations: {
    setTimeToken(state, timeToken) {
      state.timeToken = timeToken;
    },
    setToken(state, token) {
      state.token = token;
      localStorage.setItem("token", token);
    },
  },
  actions: {
    auth({ commit }, data) {
      // eslint-disable-next-line no-undef
      return axios.post("/Account/Auth", data).then((res) => {
        if (!res.data.isSuccess) {
          return {
            success: false,
            message: res.data.errors[0],
          };
        }
        commit("setTimeToken", res.data.value.oneTimeUseToken);
        return {
          success: true,
        };
      });
    },
    verifyCode({ commit }, data) {
      // eslint-disable-next-line no-undef
      return axios
        .post("/Account/VerifyCode", data)
        .then((res) => {
          if (!res.data.isSuccess) {
            return {
              success: false,
              message: res.data.errors[0],
            };
          }
          commit("setTimeToken", null);
          commit("setToken", res.data.value.token);
          return {
            success: true,
          };
        })
        .catch((err) => {
          console.log(err);
          return {
            success: false,
          };
        });
    },
  },
};
