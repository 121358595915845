// =========================================================
// * Vue Material Dashboard - v1.4.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vue-material-dashboard
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
// * Licensed under MIT (https://github.com/creativetimofficial/vue-material-dashboard/blob/master/LICENSE.md)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// import GlobalComponents from "./globalComponents";
import Notifications from "./components/NotificationPlugin";
import VueDOMPurifyHTML from "vue-dompurify-html";

// MaterialDashboard plugin
import MaterialDashboard from "./material-dashboard";

// MD Timepicker
import vueTimePicker from "@dmuy/timepicker/vue-timepicker";

import Chartist from "chartist";

import "@/plugins/vee-validate";
import "@/plugins/v-mask";
import "@/plugins/axios";
import "vue2-datepicker/index.css";
import "@/assets/scss/datepicker.scss";
import "vue2-datepicker/locale/ru";

import moment from "moment";

Vue.prototype.$Chartist = Chartist;
Vue.prototype.$lang = "ru";

Vue.use(MaterialDashboard);
// Vue.use(GlobalComponents);
Vue.use(Notifications);
Vue.use(VueDOMPurifyHTML);
Vue.use(vueTimePicker);
moment.locale("ru");

Vue.material.locale.dateFormat = "yyyy-MM-dd";

/* eslint-disable no-new */
new Vue({
  el: "#app",
  data: function () {
    return {
      Chartist: Chartist,
    };
  },
  render: (h) => h(App),
  router,
  store,
});
