import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

import auth from "./middleware/auth";

import Login from "@/pages/Login";
import Home from "@/pages/Home";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    redirect: "/users/",
    meta: {
      layout: "main",
      middleware: [auth],
    },
  },
  {
    path: "/login/",
    name: "Login",
    component: Login,
    meta: {
      layout: "empty",
    },
  },
  {
    path: "/admins/",
    name: "Настройка администраторов",
    component: () => import("@/pages/Admins"),
    redirect: "/admins/list/",
    meta: {
      layout: "main",
      middleware: [auth],
    },
    children: [
      {
        path: "/admins/list/",
        meta: {
          title: "Список администраторов",
          layout: "main",
          middleware: [auth],
        },
        component: () => import(`@/pages/Admins/List`),
      },
      {
        path: "/admins/add/",
        meta: {
          title: "Создать администратора",
          layout: "main",
          middleware: [auth],
        },
        component: () => import(`@/pages/Admins/Add`),
      },
      {
        path: "/admins/list-role/",
        meta: {
          title: "Список ролей",
          layout: "main",
          middleware: [auth],
        },
        component: () => import(`@/pages/Admins/ListRoles`),
      },
      {
        path: "/admins/add-role/",
        meta: {
          title: "Создать роль",
          layout: "main",
          middleware: [auth],
        },
        component: () => import(`@/pages/Admins/AddRole`),
      },
    ],
  },
  {
    path: "/users/",
    component: () => import("@/pages/Users"),
    meta: {
      layout: "main",
      middleware: [auth],
    },
    children: [
      {
        path: "/users/",
        meta: {
          title: "Список пользователей",
          layout: "main",
          middleware: [auth],
        },
        component: () => import("@/pages/Users/List"),
      },
      {
        path: "/users/:id",
        meta: {
          title: "Информация о пользователе",
          layout: "main",
          middleware: [auth],
        },
        component: () => import("@/pages/Users/Info"),
      },
    ],
  },
  {
    path: "/support/",
    component: () => import("@/pages/Support/Index"),
    meta: {
      title: "Обращения",
      layout: "main",
      middleware: [auth],
    },
  },
  {
    path: "/payment-requests/",
    component: () => import("@/pages/Payment/Index"),
    meta: {
      title: "Заявки на выплату",
      layout: "main",
      middleware: [auth],
    },
  },
  {
    path: "/fast-messages/",
    component: () => import("@/pages/FastMessages/Index"),
    redirect: "/fast-messages/phrases/list",
    children: [
      {
        path: "/fast-messages/phrases/list/",
        component: () => import("@/pages/FastMessages/ListPhrases"),
        meta: {
          title: "Быстрые фразы",
          layout: "main",
          middleware: [auth],
        },
      },
      {
        path: "/fast-messages/phrases/add/",
        component: () => import("@/pages/FastMessages/AddPhrase"),
        meta: {
          title: "Создать/изменить фразу",
          layout: "main",
          middleware: [auth],
        },
      },
      {
        path: "/fast-messages/emoji/list/",
        component: () => import("@/pages/FastMessages/ListEmoji"),
        meta: {
          title: "Быстрые эмоции",
          layout: "main",
          middleware: [auth],
        },
      },
      {
        path: "/fast-messages/emoji/add/",
        component: () => import("@/pages/FastMessages/AddEmoji"),
        meta: {
          title: "Создать/изменить эмоцию",
          layout: "main",
          middleware: [auth],
        },
      },
    ],
  },
  {
    path: "/forbidden-words/",
    name: "Запрещенные слова",
    component: () => import("@/pages/Words/Index"),
    meta: {
      layout: "main",
      middleware: [auth],
    },
  },
  {
    path: "/statistic/",
    component: () => import("@/pages/Statistic/Index"),
    meta: {
      title: "Статистика",
      layout: "main",
      middleware: [auth],
    },
  },
  {
    path: "/settings/",
    name: "Настройки",
    component: () => import("@/pages/Settings/Index"),
    redirect: "/settings/ban/",
    children: [
      {
        path: "/settings/ban/",
        component: () => import("@/pages/Settings/Ban"),
        meta: {
          title: "Управление временем бана",
          layout: "main",
          middleware: [auth],
        },
      },
      {
        path: "/settings/notification/",
        component: () => import("@/pages/Settings/Notification"),
        meta: {
          title: "Управление уведомлением",
          layout: "main",
          middleware: [auth],
        },
      },
      {
        path: "/settings/bids/",
        component: () => import("@/pages/Settings/Bids"),
        meta: {
          title: "Настройка ставок",
          layout: "main",
          middleware: [auth],
        },
      },
      {
        path: "/settings/comission/",
        component: () => import("@/pages/Settings/Comission"),
        meta: {
          title: "Управление комиссией",
          layout: "main",
          middleware: [auth],
        },
      },
      {
        path: "/settings/convert/",
        component: () => import("@/pages/Settings/Convert"),
        meta: {
          title: "Управление конвертацией валюты",
          layout: "main",
          middleware: [auth],
        },
      },
      {
        path: "/settings/version/",
        component: () => import("@/pages/Settings/AppVersion"),
        meta: {
          title: "Управление версией приложения",
          layout: "main",
          middleware: [auth],
        },
      },
    ],
  },
  {
    path: "/market/",
    component: () => import("@/pages/Market/Index"),
    redirect: "/market/list/",
    children: [
      {
        path: "/market/list/",
        component: () => import("@/pages/Market/List"),
        meta: {
          title: "Список предметов",
          layout: "main",
          middleware: [auth],
        },
      },
      {
        path: "/market/add/",
        component: () => import("@/pages/Market/Add"),
        meta: {
          title: "Добавить предмет",
          layout: "main",
          middleware: [auth],
        },
      },
    ],
  },
  {
    path: "/calendar/",
    redirect: "/calendar/add/",
    component: () => import("@/pages/Calendar/Index"),
    children: [
      {
        path: "/calendar/list/",
        component: () => import("@/pages/Calendar/List"),
        meta: {
          title: "Список наград за вход",
          layout: "main",
          middleware: [auth],
        },
      },
      {
        path: "/calendar/add/",
        component: () => import("@/pages/Calendar/Add"),
        meta: {
          title: "Настройка наград за вход",
          layout: "main",
          middleware: [auth],
        },
      },
    ],
  },
  {
    path: "*",
    component: () => import("@/pages/404"),
    meta: {
      title: "404 страница не найдена",
      layout: "empty",
      middleware: [auth],
    },
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  linkExactActiveClass: "nav-item active",
  linkActiveClass: "nav-item active",
});
const DEFAULT_TITLE = "Admin panel";

router.beforeEach((to, from, next) => {
  if (!to.meta.middleware) {
    return next();
  }

  document.title = to.meta.title || DEFAULT_TITLE;

  const middleware = to.meta.middleware;

  const context = {
    to,
    from,
    next,
    store,
  };
  return middleware[0]({
    ...context,
  });
});

export default router;
