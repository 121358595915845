import Vue from "vue";
import ValidationError from "@/components/ui/ValidationError";

import { extend, ValidationObserver, ValidationProvider } from "vee-validate";

import { required, email, length, min } from "vee-validate/dist/rules";

extend("required", {
  ...required,
  message: "Заполните поле",
});

extend("email", {
  ...email,
  message: "Введите корректный {_field_}",
});

extend("length", {
  ...length,
  message: "6+ символов",
});

extend("min", {
  ...min,
  message: "6+ символов",
});

Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationError", ValidationError);
