<template>
  <div class="sidebar" data-color="green">
    <div class="logo">
      <img src="@/assets/img/logo.png" alt="" />
    </div>
    <div class="logo logo-info">
      <p class="logo-info-sidebar-p">Версия сайта: {{ "1.0.1v" }}</p>
      <p class="logo-info-sidebar-p">
        Дата обновления сайта: {{ "20.04.2023" }}
      </p>
    </div>
    <div class="sidebar-wrapper">
      <slot name="content"></slot>
      <md-list class="nav">
        <slot>
          <SidebarLink
            v-for="(link, index) in sidebarLinks"
            :key="link.name + index"
            :to="link.path"
            :link="link"
          >
          </SidebarLink>
        </slot>
      </md-list>
    </div>
  </div>
</template>
<script>
import SidebarLink from "./SidebarLink.vue";

export default {
  components: {
    SidebarLink,
  },

  provide() {
    return {
      autoClose: this.autoClose,
    };
  },

  props: {
    sidebarLinks: {
      type: Array,
      default: () => [],
    },

    autoClose: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss">
.logo {
  display: flex;
  justify-content: center;
  img {
    width: 150px;
    height: 100%;
  }
}
.logo-info {
  flex-direction: column;
  width: 260px;
  &-sidebar-p {
    font-size: 14px;
    font-weight: 300;
  }
}
@media screen and (min-width: 991px) {
  .nav-mobile-menu {
    display: none;
  }
}
.sidebar-wrapper {
  overflow-y: scroll !important;
  padding-bottom: 250px;
}
</style>
