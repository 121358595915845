import Vue from "vue";
import Vuex from "vuex";

import modal from "./modules/modal";
import payments from "./modules/payments";
import statuses from "./modules/statuses";
import login from "./modules/login";
import settings from "./modules/settings";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    modal,
    payments,
    statuses,
    login,
    settings,
  },
});
