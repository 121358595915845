<template>
  <div id="app">
    <component :is="layout">
      <router-view />
    </component>
    <transition name="fade" mode="out-in">
      <Modal v-if="showModal">
        <component :is="modalComponent" :data="modalData" />
      </Modal>
    </transition>
  </div>
</template>

<script>
import EmptyLayout from "@/pages/Layout/Empty.vue";
import MainLayout from "@/pages/Layout/Main.vue";
import Modal from "./components/Modal.vue";
import { mapState } from "vuex";

export default {
  components: {
    EmptyLayout,
    MainLayout,
    Modal,
  },

  computed: {
    ...mapState({
      showModal: (state) => state.modal.showModal,
      modalComponent: (state) => state.modal.modalComponent,
      modalData: (state) => state.modal.modalData,
    }),
    layout() {
      return (this.$route.meta.layout || "empty") + "-layout";
    },
  },

  created() {
    // eslint-disable-next-line no-undef
    axios.interceptors.response.use(
      undefined,
      (error) =>
        new Promise(() => {
          // this.setError(error.response?.data?.message || "Что-то пошло не так");
          // this.$notify({
          //   message: this.error,
          //   icon: "block",
          //   horizontalAlign: "right",
          //   verticalAlign: "top",
          //   type: "danger",
          // });

          if (error?.response?.status === 401) {
            localStorage.clear();
            sessionStorage.clear();
            // eslint-disable-next-line no-undef
            axios.post(`${process.env.VUE_APP_API}/logout`);
            if (this.$route.path !== "/login") {
              this.$router.push("/login");
            }
          }
          throw error;
        })
    );
  },
};
</script>
