<template>
  <ValidationObserver ref="loginForm" :slim="true">
    <form class="login">
      <ValidationProvider
        v-slot="{ errors }"
        mode="passive"
        :slim="true"
        :rules="'required|email'"
        name="login"
      >
        <md-field>
          <ValidationError :errors="errors" />
          <label>Логин/E-mail администратора</label>
          <md-input v-model="email" />
        </md-field>
      </ValidationProvider>
      <ValidationProvider
        v-slot="{ errors }"
        mode="passive"
        :slim="true"
        :rules="'required'"
        name="password"
      >
        <md-field :md-toggle-password="true">
          <ValidationError :errors="errors" />
          <label>Пароль</label>
          <md-input v-model="password" type="password" />
        </md-field>
      </ValidationProvider>
      <ValidationProvider
        v-show="!!timeToken"
        v-slot="{ errors }"
        mode="passive"
        :slim="true"
        :rules="'required'"
        name="googleAuth"
      >
        <md-field :md-toggle-password="true">
          <ValidationError :errors="errors" />
          <label>Google authenticator</label>
          <md-input v-model="googleAuth" />
        </md-field>
      </ValidationProvider>
      <div class="btn-wrap mt">
        <md-button class="md-primary md-raised login-btn" @click="submitLogin">
          Войти
        </md-button>
        <div class="spin">
          <md-progress-spinner
            class="md-green"
            :md-diameter="15"
            :md-stroke="3"
            md-mode="indeterminate"
          />
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      email: "",
      password: "",
      googleAuth: "",
    };
  },

  computed: {
    ...mapState({
      timeToken: (state) => state.login.timeToken,
    }),
  },
  mounted() {
    // this.$refs.loginForm.setErrors({
    //   googleAuth: "ghg",
    // });
  },
  methods: {
    ...mapActions(["auth", "verifyCode"]),
    async submitLogin() {
      if (!this.timeToken) {
        const response = await this.auth({
          login: this.email,
          password: this.password,
        });
        if (!response.success) {
          this.$notify({
            message: response.message,
            icon: "do_not_disturb_alt",
            horizontalAlign: "right",
            verticalAlign: "top",
            type: "secondary",
          });
        }
      } else {
        const response = await this.verifyCode({
          oneTimeUseToken: this.timeToken,
          code: this.googleAuth,
        });
        if (response.success) {
          this.$router.push({ name: "Home" });
        } else {
          this.$notify({
            message: response.message,
            icon: "do_not_disturb_alt",
            horizontalAlign: "right",
            verticalAlign: "top",
            type: "secondary",
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 392px;
  width: 100%;

  &-btn {
    width: 100%;
    margin: 0;
  }

  .btn-wrap {
    position: relative;
    width: 100%;

    .spin {
      position: absolute;
      left: 20px;
      top: 50%;
      transform: translateY(-50%);
      width: 15px;
      height: 15px;
    }
  }

  .socials {
    justify-content: start;
    align-items: center;
    width: 100%;
    margin-top: 10px;
    color: #ffffff;

    img {
      margin-right: 10px;
    }
  }

  .social-wrapper {
    background: #5995ae;
    border-radius: 4px;
    padding: 8px;
    min-height: 38px;
    margin-right: 10px;
    cursor: pointer;
  }

  .align-items-center {
    width: 100%;
    justify-content: start;
  }
}
</style>
