export default function auth({ next, store }) {
  if (!store.state.login?.token) {
    const localToken = localStorage.getItem("token");
    if (localToken) {
      store.commit("setToken", localToken);
      return next();
    }
    return next({
      name: "Login",
    });
  }
  return next();
}
